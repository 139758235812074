import React from 'react'
import ReactDOM from 'react-dom'

import './css/index.css'
import 'font-awesome/css/font-awesome.css'

// Pages
import App from './containers/App' // wrapper

ReactDOM.render(<App />, document.getElementById('root'))
